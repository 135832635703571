/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import 'sanitize.css/sanitize.css';
import 'sanitize.css/typography.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { Route, Routes } from '~/router';

import createHistory from './browserHistory';
// Import Language Provider
import LanguageProvider from './components/LanguageProvider';
// Import i18n messages
import { translationMessages } from './i18n';
import Router from './router';
import PublicApp from './routes/public';
// Import root app
import App from './routes/root';
import configureStore from './store';
// Error logging
import setupInstrumentation from './utils/instrumentation';

// Set up error tracking
setupInstrumentation(import.meta.env.AWS_ENV, window.location);

// Create redux store with history
const initialState = {};
const history = createHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');
const root = createRoot(MOUNT_NODE);

const render = (messages) => {
  root.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <HelmetProvider>
          <Router history={store.history}>
            <Routes>
              <Route path="/public/*" element={<PublicApp />} />
              <Route path="/*" element={<App />} />
            </Routes>
          </Router>
        </HelmetProvider>
      </LanguageProvider>
    </Provider>
  );
};

if (import.meta.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  import.meta.hot.accept(['./i18n', './routes/root'], () => {
    root.unmount();
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
