import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router';

export * from 'react-router';

// A custom router that uses the history object from redux-first-history
// to keep the router in sync with the history object.
//
// Copied from https://github.com/salvoravida/redux-first-history/blob/cb621ed84f770a897171a54e58eab25b027a66da/rr6/index.js

export default function HistoryRouter({ basename, children, history }) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      future={{
        v7_relativeSplatPath: true,
      }}
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
}
