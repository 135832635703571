import { Link as RouterLink } from '~/router';

import Text from '~/components/Text';
import styled, { borders, system, variant } from '~/lib/styled';

const Link = styled(Text, {
  is: RouterLink,
  textDecoration: 'underline',
})(
  borders,
  () => ({
    cursor: 'pointer',

    '&:active, &:focus, &:hover': {
      textDecoration: 'none',
    },

    '&[disabled]': {
      '&:active, &:focus, &:hover': {
        textDecoration: 'none',
      },
    },
  }),
  variant({
    prop: 'intent',
    variants: {
      primary: {
        color: 'blue.base',
      },
      success: {
        color: 'green.base',
      },
      danger: {
        color: 'red.base',
      },
      muted: {
        color: 'neutral.N8A',
      },
    },
  }),
  system({
    textDecoration: true,
  })
);

export default Link;
