import { camelizeKeys } from 'humps';
import { parse } from 'qs';

export default function parseQuery(locationOrSearch) {
  const search =
    typeof locationOrSearch === 'string'
      ? locationOrSearch
      : locationOrSearch.search;
  return camelizeKeys(
    parse(search, {
      ignoreQueryPrefix: true,
    })
  );
}
