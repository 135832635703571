import { toaster } from 'evergreen-ui';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useParams } from '~/router';
import { createStructuredSelector } from 'reselect';

import LoadingIndicator from '~/components/LoadingIndicator';
import { publicContactFind, routes } from '~/lib/api';

import PublicContactDetail from './PublicContactDetail';

export function PublicContactPage({ error, data, publicContactFindRequest }) {
  const { hash } = useParams();
  const hasData = isEmpty(data);

  useEffect(() => {
    if (hasData) {
      publicContactFindRequest({ hash });
    }
  }, [publicContactFindRequest, hash, hasData]);

  const onSubmit = ({ integration, ...params }) => {
    routes.payments.new({ params, integration }).then(({ body }) => {
      if (body.errorMessage) {
        toaster.danger(body.errorMessage);
      }

      if (body.redirectTo) {
        window.location = body.redirectTo;
      }
    });
  };

  if (data.redirectTo) {
    window.location = data.redirectTo;
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <div>
      <Helmet titleTemplate="" title={data?.contact?.organisation?.name} />
      {isEmpty(data) ? (
        <LoadingIndicator block p={4} />
      ) : (
        <PublicContactDetail {...data.contact} onSubmit={onSubmit} />
      )}
    </div>
  );
}

PublicContactPage.propTypes = {
  publicContactFindRequest: PropTypes.func.isRequired,
  data: PropTypes.object,
};

const mapDispatchToProps = {
  publicContactFindRequest: publicContactFind.request,
};

const mapStateToProps = createStructuredSelector({
  data: publicContactFind.selectResults,
  loading: publicContactFind.selectLoading,
  error: publicContactFind.selectError,
  errorMessage: publicContactFind.selectErrorMessage,
});

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(PublicContactPage);
