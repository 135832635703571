import React from 'react';
import { Navigate, useLocation } from '~/router';

const replacePathname = (location, regex, replacement) => ({
  ...location,
  pathname: location.pathname.replace(regex, replacement),
});

const ReplaceRedirect = ({ pattern = /^(.*)$/, to, ...props }) => {
  const location = useLocation();
  return <Navigate {...props} to={replacePathname(location, pattern, to)} />;
};

export default ReplaceRedirect;
