/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { compose } from 'redux';

import ErrorBoundary from '~/components/ErrorBoundary';
import RootLayout from '~/components/Layouts/Root';
import ReplaceRedirect from '~/components/ReplaceRedirect';
import withAuthReducer from '~/reducers/auth';
import errorReducer, { key as errorKey } from '~/reducers/error';
import withResetPasswordReducer from '~/reducers/resetPassword';
import { Route, Routes } from '~/router';
import authSaga from '~/sagas/auth';
import rootSaga from '~/sagas/root';
import { injectReducers, injectSagas } from '~/utils/injectors';
import loadable from '~/utils/loadable';

const OpenIdPage = loadable(() => import('~/pages/OpenIdPage'));
const LoginPage = loadable(() => import('~/pages/LoginPage'));
const LogoutPage = loadable(() => import('~/pages/LogoutPage'));
const CurrentUserRoute = loadable(() => import('~/routes/currentUser'));
const RegisterRoute = loadable(() => import('~/routes/register'));
const ForgotPage = loadable(() => import('~/pages/ForgotPasswordPage'));
const ResetPage = loadable(() => import('~/pages/ResetPasswordPage'));

export class RootRoute extends React.PureComponent {
  componentDidMount() {
    // WARNING: side-effect
    document.body.classList.remove('loading');
  }

  render() {
    return (
      <RootLayout>
        <ErrorBoundary>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/connect/:provider" element={<OpenIdPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/register/*" element={<RegisterRoute />} />
            <Route path="/forgot-password" element={<ForgotPage />} />
            <Route path="/reset-password" element={<ResetPage />} />
            <Route
              path="/reset_password"
              element={<ReplaceRedirect to="/reset-password" />}
            />
            <Route path="/*" element={<CurrentUserRoute />} />
          </Routes>
        </ErrorBoundary>
      </RootLayout>
    );
  }
}

export default compose(
  // Ensure auth reducer is before auth saga, so that reducer is running before
  // TOKEN_SET action is called
  injectReducers([
    withAuthReducer,
    { key: errorKey, reducer: errorReducer },
    withResetPasswordReducer,
  ]),
  injectSagas([
    { key: 'rootSaga', saga: rootSaga },
    { key: 'auth', saga: authSaga },
  ])
)(RootRoute);
