/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Route, Routes } from '~/router';

import ErrorBoundary from '~/components/ErrorBoundary';
import { PublicLayout } from '~/components/Layouts/Root';
// Main bundle routes
import PublicContactPage from '~/pages/Public/ContactPage';
import PublicInvoicePage from '~/pages/Public/InvoicePage';
import PublicPaymentReturnPage from '~/pages/Public/PaymentReturnPage';
// Sagas & Reducers
import rootSaga from '~/sagas/root';
import { injectSagas } from '~/utils/injectors';

export function PublicRootRoute() {
  return (
    <PublicLayout>
      <ErrorBoundary>
        <Routes>
          <Route
            path={`client/invoices/:hash`}
            element={<PublicInvoicePage />}
          />
          <Route path={`client/:hash`} element={<PublicContactPage />} />
          <Route
            path={`client/:contactHash/invoices/:hash`}
            element={<PublicInvoicePage />}
          />
          <Route
            path={`client/:hash/:integration?/:status?`}
            element={<PublicPaymentReturnPage />}
          />
        </Routes>
      </ErrorBoundary>
    </PublicLayout>
  );
}

export default injectSagas([{ key: 'rootSaga', saga: rootSaga }])(
  PublicRootRoute
);
